<template>
  <transition name="fade" class="popup">
    <div class="modal container" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog popup-content">
        <div class="modal__header popup-header">
          <slot name="header" />
          <img
            class="closer"
            src="@/assets/images/closer.svg"
            alt="Close"
            @click="closeModal()"
          />
        </div>

        <div class="modal__body scroll-content">
          <slot name="body" />
        </div>

        <div v-if="showCloseButton" class="modal__footer center-xs">
          <!--          <slot name="footer" />-->
          <div class="button" @click="closeModal()">Kapat</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {
      show: false,
      showCloseButton: true
    }
  },
  methods: {
    closeModal() {
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openModal(showCloseButton = true) {
      this.showCloseButton = showCloseButton
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
    }
  }
}
</script>
