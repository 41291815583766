import Vue from 'vue'

const locales = {
  tr: {
    INTERNAL_SERVER_ERROR:
      'İşlem esnasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    RESOURCE_NOT_FOUND: 'Erişmek istediğiniz kaynak bulunamadı.',
    RESOURCE_ALREADY_EXISTS:
      'Sistemde bu bilgilerle bir kayıt bulunmaktadır. Lütfen kontrol edin.',
    DATA_INTEGRITY_VIOLATION:
      'Bu bilgiler başka bir kayıt için daha önce kullanılmıştır. Lütfen kontrol edin.',
    INVALID_REQUEST_FORMAT: 'Geçersiz formatta bir veri gönderildi.',
    VALIDATION_ERROR: 'Lütfen gönderdiğiniz değerleri kontrol edin.',
    INVALID_VALUE:
      'Yanlış bir değer gönderdiniz. Lütfen gönderdiğiniz değerleri kontrol edin.',
    MAX_UPLOAD_SIZE_EXCEEDED:
      "Yüklemeye çalıştığınız dosyanın boyutu 5MB'dan yüksektir. Lütfen kontrol edin.",
    SUBSCRIBER_NOT_EDITABLE: 'Bu abone için değişiklik yapılamaz.',
    PAYMENT_INIT_FAILED: '3dsecure initialize işlemi sırasında hata oluştu'
  }
}

export default {
  install: () => {
    Vue.prototype.$localeError = key =>
      locales.tr[key] ? locales.tr[key] : key
  }
}
