<template>
  <div id="app">
    <div id="nav">
      <Header></Header>
      <router-view />
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Yeni Talep',
    // all titles will be injected into this template
    titleTemplate: '%s | Pure Energy'
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/app.scss';
</style>
