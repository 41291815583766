import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import HomeStn from '../views/HomeStn.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Pure Energy'
    }
  },
  {
    path: '/stn',
    name: 'HomeStn',
    component: HomeStn,
    meta: {
      title: 'Pure Energy'
    }
  },
  {
    path: '/wizard',
    name: 'Wizard',
    // props: route => ({ step: route.query.step || '1' }),
    // route level code-splitting
    // this generates a separate chunk (wizard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wizard" */ '../views/Wizard.vue')
  },
  {
    path: '/subscribers/:subscriberId/card-update',
    name: 'Card Update',
    query: route => ({ token: route.query.token}),
    // route level code-splitting
    // this generates a separate chunk (wizard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wizard" */ '../views/CardUpdate.vue')
  }
]

const router = new VueRouter({
  // mode: 'history', // enable for removing hash from url
  base: process.env.BASE_URL,
  routes
})


export default router
