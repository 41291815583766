<template>
  <transition name="fade" class="popup">
    <div class="modal container" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog popup-content">
        <div class="modal__header popup-header">
          <div class="popup-header">Sıkça Sorulan Sorular</div>
          <img
            class="closer"
            src="@/assets/images/closer.svg"
            alt="Close"
            @click="closeModal()"
          />
        </div>

        <div class="modal__body scroll-content">
          <h4 align="left" style="margin-bottom: 10px">ABONELİK/FESİH</h4>
          <h5 align="left" style="color:#e5302d;">
            Abonelik için hangi evraklar gereklidir?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Abonelik için vergi levhanız ve son aya ait elektrik faturanız
            yeterlidir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Türkiye’nin her yerinden Pure Energy yoluyla elektrik tedarik
            edebilir miyim?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Evet, Türkiye'nin her yerinden Pure Energy yoluyla elektrik
            tedarik edebilirsiniz.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Abonelik için aylık elektrik tüketimimin minimum ne kadar olması
            gerekmektedir?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Yıllık elektrik tüketiminizin 'Serbest Tüketici' limitinden fazla
            olması yeterlidir. 2021 senesi için belirlenen serbest tüketici
            limiti yıllık 1200 kWh’ dir ve ticarethane tarifesinden elektrik
            tüketen aboneler için bu miktar aylık ortalama 110 TL’lik faturaya
            karşılık gelmektedir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Pure Energy’den elektrik tedarik etmenin avantajları nelerdir?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Sunulan indirim oranı sayesinde ulusal elektrik tarifelerinden daha
            ucuza elektrik tedarik edebilirsiniz. Ayrıca sabit fiyat kampanyası
            kapsamında aktif elektrik tarifelerine gelen zamlardan da
            etkilenmezsiniz. Abonelik geçiş süreçlerinizin tamamı dijital olarak
            zahmetsizce gerçekleştirilir. Tedarik ettiğimiz %100 yenilenebilir
            enerji i-REC standartlarıyla sertifikalandırılacaktır.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Abone olursam, şu an kullandığım elektriğin kalitesinde değişiklik
            olur mu?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Elektrik tüketiminiz mevcut sisteminiz üzerinden aynen devam edecek,
            altyapınızda ve elektrik kalitesinde hiçbir değişiklik olmayacaktır.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Abonelik ve fesih işlemlerini hangi kanallardan yapabilirim?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Abonelik feshi için başvurunuz iletisim@pureenergy.com.tr adresine
            gönderildiğinde fesih başvurunuz sözleşmeniz kapsamında işleme
            alınacaktır.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Sözleşme sürem bittiğinde ne oluyor?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Sözleşme koşulları kapsamında herhangi bir çıkış talebi gelmediği
            müddetçe sözleşmeniz güncel kampanya koşulları çerçevesinde
            yenilenecektir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Sözleşme sürem biterse veya sözleşmem feshedilirse elektrik
            kesintisi yaşar mıyım?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Elektrik faturanızı ödediğiniz takdirde, bu süreçte elektrik
            kesintisi yaşamazsınız. Elektriğiniz başka bir tedarikçiden veya
            bölgenizdeki görevli tedarik şirketinden almaya devam edersiniz.
          </h6>
          <h4 align="left" style="margin-bottom: 10px">ÖDEME/FATURA</h4>
          <h5 align="left" style="color:#e5302d;">
            Faturamı hangi kanallar vasıtasıyla ödeyebilirim?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Tahmini faturanız her ayın son günü oluşturulur ve 3 gün içerisinde
            sisteme kaydettiğiniz kredi kartınızdan otomatik olarak tahsil
            edilir. Tahmini faturanız ile gerçekleşen tüketimiz arasında fark
            olması durumunda ilgili fark bedeli bir sonraki faturanıza mahsup
            edilecektir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Faturam bana nasıl ulaştırılacak?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Faturalarınız size e-posta yoluyla ulaştırılacaktır.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Elektrik tarifelerine gelen zam faturamı nasıl etkileyecek?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Kampanya koşulları çerçevesinde, sözleşmenizin imzalandığı tarihteki
            aktif enerji bedeli üzerinden yapılacak indirim ile sabit elektrik
            fiyatınız belirlenecektir. Sözleşme dönemi içerisinde ulusal
            elektrik tarifeleri aktif enerji bedeli değişikliklerinden
            etkilenmeden sabit aktif enerji bedeli üzerinden elektrik tedarik
            etmeye devam edebilirsiniz.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Tüketim bedeline uygulanan indirim neden dağıtım bedeli ve diğer
            kalemlere uygulanmıyor?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Dağıtım bedeli ve diğer kalemler kamu kurum ve kuruluşları
            tarafından alınan vergiler, fonlar ve diğer bedellerden oluşmaktadır
            ve belirli dönemlerde EPDK tarafından güncellenmektedir. Tedarik
            şirketlerinin tarife kapsamında belirlenen bu bedeller uzerinden
            indirim yapılması mümkün degildir. Tahsil edilen bu bedeller tarife
            kapsamında ilgili kamu kurum ve kuruluşlarına tüketiciler adına
            aynen ödenmektedir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Elektrik faturamda gördüğüm bedeller nedir?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            <ul>
              <li>Aktif Enerji Bedeli</li>
              <li>Dağıtım Bedeli</li>
              <li>TRT Payı</li>
              <li>BTV (Belediye Tüketim Vergisi)</li>
              <li>Enerji Fonu</li>
              <li>Reaktif Bedeli</li>
            </ul>
          </h6>
          <h4 align="left" style="margin-bottom: 10px">ARIZA/KESİNTİ</h4>
          <h5 align="left" style="color:#e5302d;">
            Arıza ve bakım nedeniyle yapılan kesintilerde bilgilendirme hangi
            kurum tarafından yapılacak?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Elektrik şebekesindeki kesinti,arıza, bakım vb. altyapı ile ilgili
            konular, bulunduğunuz bölgedeki görevli dağıtım şirketinin yetki ve
            sorumluluğu altındadır. Elektrik tedarikçinizden bağımsız olarak,
            elektrik kesintileri, arıza ve bakımlara ilişkin tüm
            bilgilendirmeler, mevcut durumda olduğu gibi görevli dağıtım
            şirketiniz tarafından yapılacaktır.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Elektrik arızası veya kesintisi yaşarsam kiminle irtibata
            geçmeliyim?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Elektrik şebekesindeki kesinti, arıza, bakım vb. altyapı ile ilgili
            konular, bulunduğunuz bölgedeki görevli dağıtım şirketinin yetki ve
            sorumluluğu altındadır. Böyle bir durumla karşılaştığınızda görevli
            dağıtım şirketiniz ile irtibata geçip, bilgi alabilir, gerekli
            durumlarda arıza kaydı oluşturabilirsiniz.
          </h6>
          <h4 align="left" style="margin-bottom: 10px">SAYAÇ</h4>
          <h5 align="left" style="color:#e5302d;">
            Aboneliğimi Pure Energy’ye taşırsam sayaç değişikliği gerekli midir?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Sayaç değişikliği gerekli değildir.
          </h6>
          <h5 align="left" style="color:#e5302d;">
            Elektrik sayacım hangi kurum tarafından hangi sıklıkla okunacak?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Sayaçların okunması bölgenizdeki görevli dağıtım şirketinin görev ve
            sorumluluğunda bulunmakta olup tedarikçinizden bağımsız olarak aynen
            yapılmaya devam edecektir. Sayaç okumaları normal prosedür
            kapsamında ayda bir defa yapılmaktadır.
          </h6>
          <h4 align="left" style="margin-bottom: 10px">ONLINE HİZMETLER</h4>
          <h5 align="left" style="color:#e5302d;">
            Geçmiş dönemlere ait fatura ve tüketim bilgilerine nereden
            erişebilirim?
          </h5>
          <h6 align="left" style="margin-bottom: 10px">
            Bölgenizdeki görevli tedarik şirketinden geçmiş dönemlerinize ait
            olan fatura ve tüketim bilgilerinize ulaşabilirsiniz.
          </h6>
        </div>

        <div v-if="showCloseButton" class="modal__footer center-xs">
          <!--          <slot name="footer" />-->
          <div class="button" @click="closeModal()">Kapat</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FaqModal',
  components: {},
  data() {
    return {
      show: false,
      showCloseButton: true
    }
  },
  methods: {
    closeModal() {
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openModal(showCloseButton = true) {
      this.showCloseButton = showCloseButton
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
    }
  }
}
</script>
