import Vue from 'vue'
import axios from 'axios'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'

import store from './store'
import App from './App.vue'
import router from './router'
import helpers from './plugins/helpers'
import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyD4xN27FqCTekK28mDECCXy5-tltfpFVvY',
  authDomain: 'pure-campaign.firebaseapp.com',
  projectId: 'pure-campaign',
  storageBucket: 'pure-campaign.appspot.com',
  messagingSenderId: '428568907725',
  appId: '1:428568907725:web:926ec27b8ddddd23733e84',
  measurementId: 'G-Z7901SXTMC'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.prototype.$analytics = firebase.analytics()

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(helpers)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
