import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    step: 0,
    completed: 0,
    salesRepNumber: '',
    subscriber: {}
  },
  getters: {
    step(state) {
      return state.step
    },
    completed(state) {
      return state.completed
    },
    salesRepNumber(state) {
      return state.salesRepNumber
    },
    subscriber(state) {
      return state.subscriber
    }
  },
  mutations: {
    setStep(state, payload) {
      state.step = payload
    },
    incrementStep(state) {
      state.step++
    },
    resetStep(state) {
      state.step = 0
    },
    setCompleted(state, payload) {
      state.completed = payload
    },
    incrementCompleted(state) {
      state.completed++
    },
    resetCompleted(state) {
      state.completed = 0
    },
    setSalesRepNumber(state, payload) {
      state.salesRepNumber = payload
    },
    setSubscriber(state, payload) {
      state.subscriber = payload
    }
  },
  actions: {
    setStepAction(context, payload) {
      context.commit('setStep', payload)
    },
    incrementStepAction(context) {
      context.commit('incrementStep')
    },
    resetStepAction(context) {
      context.commit('resetStep')
    },
    setCompletedAction(context, payload) {
      context.commit('setCompleted', payload)
    },
    incrementCompletedAction(context) {
      context.commit('incrementCompleted')
    },
    resetCompletedAction(context) {
      context.commit('resetCompleted')
    },
    setSalesRepNumber(context, payload) {
      context.commit('setSalesRepNumber', payload)
    },
    setSubscriberAction(context, payload) {
      context.commit('setSubscriber', payload)
    }
  },
  plugins: [createPersistedState()]
})
