<template>
  <header id="nav">
    <div class="container">
      <div class="row start-md middle-xs">
        <div class="col-xs-6 col-lg-4 first-lg">
          <a href="https://pureenergy.com.tr">
            <img
              class="brand-logo"
              src="@/assets/images/logo.svg"
              alt="Pure Energy"
              width="186px"
              height="25px"
            />
          </a>
        </div>
        <div class="col-xs-12 col-lg-4 center last-xs first-lg">
          <h1 class="page-header">
            {{ $route.path == '/wizard' ? 'YENİ TALEP' : '' }}
          </h1>
        </div>
        <div class="col-xs-6 col-lg-4 right">
          <a href="https://www.vodafone.com.tr/">
            <img src="@/assets/images/vodafone-2017-logo.svg" alt="Vodafone" />
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>
