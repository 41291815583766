<template>
  <div class="container">
    <section class="hero">
      <h3><br><br><br><br><br></h3>
      <p class="description">
        Şu anda bakım aşamasındayız. Çok yakında tekrar sizlerleyiz.
      </p>
      <h3><br><br><br><br><br></h3>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Maintenance',
  components: {
  },
  created() {
  },
  methods: {
  }
}
</script>
